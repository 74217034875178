import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import * as PropTypes from 'prop-types';

import ErrorLayout from '$landing-page/layouts/error-layout';

const query = graphql`
    query errorInternalServerErrorPage {
        image: file(relativePath: { eq: "page-error-internal-server-error.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, width: 360, quality: 100, placeholder: NONE)
            }
        }
    }
`;

const ErrorInternalServerErrorPage = (props) => {
    const { location } = props;
    const queryData = useStaticQuery(query);
    return (
        <ErrorLayout
            pathname={location.pathname}
            image={queryData.image}
            title="Internal Server Error"
            description="Something went wrong!"
        />
    );
};

ErrorInternalServerErrorPage.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

export default ErrorInternalServerErrorPage;
